<template>
	<div>
		<b-row class="form">
			<b-col sm="12" md="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
					{{ FormMSG(123, 'Premises') }}
				</h1>
			</b-col>
			<b-col cols="12">
				<transition name="slide">
					<b-card no-body class="card-border-blue-light">
						<b-card-body>
							<premises />
						</b-card-body>
					</b-card>
				</transition>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import premises from '@/views/Premises/premises.vue';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'premiseList',

	mixins: [languageMessages],

	components: {
		premises
	}
};
</script>
