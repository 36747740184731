var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "form" },
        [
          _c("b-col", { attrs: { sm: "12", md: "12" } }, [
            _c(
              "h1",
              {
                class: [
                  `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                  { "is-pwa": _vm.$isPwa() },
                ],
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(123, "Premises")) +
                    "\n\t\t\t"
                ),
              ]
            ),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "transition",
                { attrs: { name: "slide" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "card-border-blue-light",
                      attrs: { "no-body": "" },
                    },
                    [_c("b-card-body", [_c("premises")], 1)],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }